<template>
	<div class="search_list">
		<s-header :name="title"></s-header>
		<van-search :clearable="false" shape="round" v-model="searchText" @update:model-value="onSearch" placeholder="搜索你需要的商品热门关键词" />
		<van-tabs v-model:active="active" @click="onClickTab">
			<van-tab title="门店热卖" name="1"></van-tab>
			<van-tab title="团购商品" name="2"></van-tab>
		</van-tabs>
		<div class="product_list">
			<van-list v-model:loading="loading" :finished="finished" finished-text="没有更多了" @load="initData" @offset="10">
				<good-item :list="list"></good-item>
			</van-list>
		</div>
	</div>
</template>

<script>
	import {
		toRefs,
		reactive,
		onMounted
	} from 'vue'
	import {
		useRoute,
		useRouter
	} from 'vue-router'
	import {
		tg_goodList
	} from '@/service/good'
	import sHeader from '@/components/SimpleHeader'
	import goodItem from '@/components/GoodItem'
	export default {
		components: {
			sHeader,
			goodItem
		},
		setup() {
			const route = useRoute()
			const router = useRouter()
			const state = reactive({
				active: 1,
				title: '',
				searchText: '',
				goodsTypeId: 1,
				loading: false,
				finished: false,
				list: [],
				page: 1,
				totalSize: 0
			})
			onMounted(async () => {
				state.title = route.query.keyword;
			})
			const initData = async () => {
				state.loading = true;
				const res = await tg_goodList({
					goodsName: state.title,
					goodsTypeId: state.goodsTypeId
				})
				if(res.errno == 0 && res.data.list.length) {
					state.totalSize = res.data.total;
					if(state.list.length >= state.totalSize){
						return state.finished = true;
					}else{
						state.page = state.page + 1;
						state.list = state.list.concat(res.data.list)
					};
					state.loading = false;
				} else {
					state.finished = true;
					state.loading = false;
				}
			}
			const onSearch = (value) => {
				state.page = 1;
				state.list = [];
				state.title = value;
				initData();
			}
			const onClickTab = (value) => {
				state.page = 1;
				state.list = [];
				state.goodsTypeId = value;
				initData();
				
			};
			return {
				...toRefs(state),
				onSearch,
				onClickTab,
				initData,
			}
		}
	}
</script>

<style scoped lang="less">
	.search_list {
		width: 100vw;
		background-color: #f5f5f5;
	}

	.product_list {
		width: 92%;
		margin: 16px auto;
	}
</style>
